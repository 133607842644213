import React from "react";
import WhatWeDo from "../../components/ReusableData";
import HeaderImage from "../../components/HeaderImage";

const OpenAccessPage: React.FC = () => {
  const sections = ["Institutional", "National", "Regional", "Continental"];
  const content = {
    Institutional: `At the institutional level, LIBSENSE is organising a series of regional workshops collocated with the REN conferences/meetings of UA, WACREN and ASREN that target decision-makers responsible for developing and implementing open science policies around research and education. Among the stakeholder groups are deputy-vice chancellors responsible for academic affairs or research and development in African higher education institutions; directors of postgraduate study responsible for researcher development and quality assurance; and library directors accountable for supporting the infrastructures for implementing open science. <br></br> The focus of these workshops is how to move from the high-level goals, principles and policies of open science as articulated, for example, in the UNESCO recommendations on open science, to the role of institutions in implementing these policies. For this activity, LIBSENSE has been partnering with associations in which senior HEI executives are already active such as AAU, AARU, CRUFAOCI, IUCEA, and SARUA, to co-organise the workshops. Since these senior executives are working at the institutional level, their work would complement activity at the national level and help progress the open science agenda across the African region.`,
    National:
      "Content for the National section. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    Regional:
      "Content for the Regional section. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    Continental:
      "Content for the Continental section. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  };

  return (
    <>
      <HeaderImage />
      <WhatWeDo
        sections={sections}
        content={content}
        title="Open Access Infrastructure"
        paragraphs={["Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus recusandae, voluptatibus temporibus autem voluptatum fugiat saepe? Dolor quo corrupti rerum quod in ea obcaecati ipsum, maiores perferendis. Facilis, tenetur explicabo?"]}
      />
    </>
  );
};


export default OpenAccessPage;
