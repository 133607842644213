// import React from "react";
// import { useNavigate } from "react-router-dom";

// const HeaderBanner: React.FC = () => {
//   const navigate = useNavigate();

//   const handleReadMoreClick = (link: string) => {
//     navigate(link);
//   };

//   return (
//     <div
//       className="relative h-screen bg-cover bg-center"
//       style={{ backgroundImage: "url('/assets/images/hero.jpeg')" }}
//     >
//       {/* Gradient Overlay */}
//       <div className="relative z-10 flex flex-col justify-center items-start h-full">
//         <div className="bg-gradient-to-r from-blue-900 to-transparent p-8 rounded-sm">
//           <h1 className="text-white max-w-lg text-xxl md:text-6xl font-bold mb-4">
//             Empowering Africa Through Open Science
//           </h1>
//           <p className="text-white text-xs md:text-sm max-w-md mb-6">
//             LIBSENSE (Library Support for Embedded NREN Services and
//             E-infrastructure) is a{" "}
//             <span className="cursor-pointer font-medium text-primary">
//               <a href="https://wacren.net">WACREN</a>
//             </span>
//             -initiated, community-driven, pan-African programme aimed at
//             building a community of practice for open science ...
//           </p>
//           <button
//             className="w-1/2 hover:bg-primary-600 flex-none rounded-md bg-primary px-4 py-2.5 text-xs font-semibold text-primary-foreground"
//             onClick={() => handleReadMoreClick("/about-us")}
//           >
//             Read More
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HeaderBanner;

import React from "react";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the styles

const HeaderBanner: React.FC = () => {
  const navigate = useNavigate();

  const handleReadMoreClick = (link: string) => {
    navigate(link);
  };

  const slides = [
    {
      title: "Empowering Africa Through Open Science",
      description:
        "LIBSENSE (Library Support for Embedded NREN Services and E-infrastructure) is a WACREN-initiated, community-driven, pan-African programme aimed at building a community of practice for open science...",
      buttonText: "Read More",
      link: "/about-us",
      imageUrl: "/assets/images/hero.jpeg",
    },
    {
      title: "Collaborate for Innovation",
      description:
        "Join the largest network of libraries and researchers in Africa and be part of a transformative movement in open science and research dissemination.",
      buttonText: "Join Us",
      link: "/join",
      imageUrl: "/assets/images/hero.jpeg",
    },
    {
      title: "Unlock New Opportunities",
      description:
        "Discover resources, collaborate with experts, and access a wide range of services to support your research and innovation goals.",
      buttonText: "Explore",
      link: "/explore",
      imageUrl: "/assets/images/hero.jpeg",
    },
  ];

  return (
    <Carousel
      showArrows={true}
      infiniteLoop={true}
      autoPlay={true}
      interval={5000}
      showThumbs={false}
      showStatus={false}
    >
      {slides.map((slide, index) => (
        <div
          key={index}
          className="relative h-screen bg-cover bg-center"
          style={{ backgroundImage: `url(${slide.imageUrl})` }}
        >
          <div className="relative z-10 flex flex-col justify-center items-start h-full">
            <div className="bg-gradient-to-r from-blue-900 to-transparent p-8 rounded-sm">
              <h1 className="text-white max-w-lg text-xxl md:text-6xl font-bold mb-4">
                {slide.title}
              </h1>
              <p className="text-white text-xs md:text-sm max-w-md mb-6">
                {slide.description}
              </p>
              <button
                className="w-1/2 hover:bg-primary-600 flex-none rounded-md bg-primary px-4 py-2.5 text-xs font-semibold text-primary-foreground"
                onClick={() => handleReadMoreClick(slide.link)}
              >
                {slide.buttonText}
              </button>
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default HeaderBanner;
