import React from "react";
import CountUp from "react-countup";

interface StatProps {
  value: number;
  label: string;
  suffix?: string;
  animate?: boolean;
}

const Stats: React.FC<StatProps> = ({ value, label, suffix, animate }) => {
  return animate ? (
    <div className="text-center">
      <CountUp
        end={value}
        duration={6}
        className="text-xxl font-bold text-foreground"
        suffix={suffix}
      />
      <div className="text-sm font-normal mt-2 text-foreground mr-2">
        {label}
      </div>
    </div>
  ) : (
    <div>&nbsp;</div>
  );
};

export default Stats;
