import React from "react";
import { Link } from "react-router-dom";

const RegionalPage: React.FC = () => {
  const events = [
    {
      name: "Workshop 1",
      link: "regional-workshop-1",
      title: "Regional policy Workshop 1 - Ubuntunet Connect 2021",
      content: 1,
    },
    {
      name: "Workshop 2",
      link: "regional-workshop-2",
      title: "Regional Policy Workshop 2 at WACREN 2022",
      content: 2,
    },
    {
      name: "Workshop 3",
      link: "regional-workshop-3",
      title:
        "LIBSENSE Leadership Engagement Meeting on Policy and Practice: Strengthening African Research for Development",

      content: 3,
    },
    {
      name: "DORA webinar",
      link: "libsense-and-dora-webinar",
      title: "LIBSENSE and DORA webinar on research assessment in Africa",
      content: 4,
    },
  ];

  return (
    <>
      <p className="text-sm text-foreground mb-4">
        LIBSENSE engages in various activities to foster the development and
        implementation of open science policies across Africa. These activities
        are designed to support regional collaboration, enhance research
        visibility, and build capacity among academic and research institutions.
      </p>
      <p className="text-sm text-foreground mb-4">
        Here’s an overview of our key activities:
      </p>

      <h3 className="text-lg font-semibold text-foreground mb-4">
        Policy Collaboration and Development
      </h3>
      <p className="text-sm text-foreground mb-4">
        LIBSENSE collaborates with regional higher education regulatory and
        oversight bodies to establish comprehensive, cross-cutting regional open
        science policies. These collaborations aim to align regional policies
        with the openness, transparency, and inclusivity principles outlined in
        the UNESCO Recommendations on Open Science.
      </p>

      <h3 className="text-lg font-semibold text-foreground mb-4">
        Infrastructure Development
      </h3>
      <p className="text-sm text-foreground mb-4">
        LIBSENSE partners with research and education networks (RENs) to develop
        and manage tailored, community-driven open access (OA) platforms and
        services. These initiatives are focused on:
      </p>
      <ul className="text-sm text-foreground mb-4">
        <li className="mb-2">
          - Increasing the visibility of African scholarly outputs
        </li>
        <li className="mb-2">
          - Enhancing the accessibility and interoperability of research data
          repositories
        </li>
        <li className="mb-2">
          - Promoting the use of persistent identifiers to ensure the long-term
          discoverability of research outputs
        </li>
      </ul>
      <p className="text-sm text-foreground mb-4">
        Additionally, the regional catchall infrastructure from WACREN to
        support the initiatives serves as a complement to these efforts.
      </p>

      <section className="mb-4">
        <h3 className="text-lg font-semibold text-foreground mb-4">
          Capacity Building
        </h3>
        <p className="text-sm text-foreground mb-4">
          To empower stakeholders in the academic research and publishing
          ecosystem, LIBSENSE offers various capacity-building initiatives,
          including:
        </p>
        <ul className="text-sm text-foreground mb-4">
          <li className="mb-2">
            - Webinars on skills profiles and open access publishing
          </li>
          <li className="mb-2">
            - Training on research data repository management and
            interoperability
          </li>
          <li className="mb-2">
            - Workshops on the use of persistent identifiers and eduID
          </li>
          <li className="mb-2">
            - Development programs for Early Career Researchers, including
            training on research ethics and best practices
          </li>
          <li className="mb-2">
            - Initiatives to discover and curate Arabic scholarly content
            through specialised harvester development
          </li>
        </ul>
      </section>
      <section className="mb-4">
        <h3 className="text-lg font-semibold text-foreground mb-4">
          Annual Regional Leadership Meetings
        </h3>
        <p className="text-sm text-foreground mb-4">
          LIBSENSE organises annual research and education leadership meetings
          to address themes requiring regional collaboration. These meetings aim
          to:
        </p>
        <ul className="text-sm text-foreground mb-4">
          <li className="mb-2">
            1. Foster active engagement among university leaders, regulators,
            and research funders
          </li>
          <li className="mb-2">
            2. Highlight recent policy advancements and explore opportunities
            for national and international cooperation.
          </li>
          <li className="mb-2">
            3. Promote leadership in research excellence and the establishment
            of a sustainable research ecosystem
          </li>
        </ul>
      </section>

      <div className="flex justify-center space-x-4 mt-8">
        {events.map((event, index) => (
          <Link
            key={index}
            to={`/open-science/${event.link}`}
            state={{ eventData: event }}
            className="block text-sm text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
          >
            {event.name}
          </Link>
        ))}
      </div>
    </>
  );
};

export default RegionalPage;
