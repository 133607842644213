import React from "react";
import SharedRepositories from "../../components/SharedRepositories";
import HeaderImage from "../../components/HeaderImage";

const PIDsService: React.FC = () => {
  const handleDocumentClick = () => {
    window.open("https://pidslink.wacren.net/", "_blank");
  };

  return (
    <>
      <HeaderImage />

      <div className="min-h-screen ">
        <SharedRepositories
          title="PIDs Service"
          paragraphs={[
            "PIDsLink is an ARKs-based platform for managing persistent identifiers (PIDs). These identifiers ensure that digital objects, such as research papers and datasets, remain accessible and citable over time, even if their locations or URLs change. PIDsLink will be integrated with both BAOBAB and PublishNow, providing a seamless way to assign and manage PIDs for the digital assets in these systems, thereby enhancing their discoverability and reliability.",
          ]}
          buttons={[
            {
              label: "Read More",
              onClick: handleDocumentClick,
              variant: "default",
            },
          ]}
        />
      </div>
    </>
  );
};

export default PIDsService;
