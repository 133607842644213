import React from "react";
import CollaborationCard from "../../components/CollaborationsCard";
import HeaderImage from "../../components/HeaderImage";

const Collaborations: React.FC = () => {
  return (
    <>
      <HeaderImage />

      <div className="max-w-4xl mx-auto mb-12 p-4">
        <h2 className="text-center text-xxl font-bold text-foreground mb-8">
          Collaborations
        </h2>
        <p className="text-sm text-foreground mb-4">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
          ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua.
        </p>

        <div className="space-y-8">
          <CollaborationCard
            title="LIBSENSE - RUFORUM COOPERATION"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            buttons={["MOU", "Activities"]}
          />

          <CollaborationCard
            title="EIFL - LIBSENSE OPEN PUBLISHING"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            importantLinks={[
              "Lorem ipsum dolor sit amet",
              "Lorem ipsum dolor sit amet",
              "Lorem ipsum dolor sit amet",
            ]}
          />

          <CollaborationCard
            title="LIBSENSE - REFORUM COOPERATION"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            importantLinks={[
              "Lorem ipsum dolor sit amet",
              "Lorem ipsum dolor sit amet",
              "Lorem ipsum dolor sit amet",
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default Collaborations;
