import React from "react";
import HeaderImage from "../components/HeaderImage";

const ContactUs: React.FC = () => {
  return <div>
      <HeaderImage />

  </div>;
};

export default ContactUs;
