import React from "react";
import HeaderBanner from "../../components/Header";
import { Link } from "react-router-dom";

const ECRNigeriaPage: React.FC = () => {
  const Team = [
    {
      name: "Dr Fatimah Abduldayan",
      university: "Federal University of Technology, Minna",
      link: "https://www.linkedin.com/in/fatimahjibrilabduldayan/",
    },
    {
      name: " Dr Opeyemi Quadir",
      university: "Ahmadu Bello University, Zaria",
      link: "https://www.linkedin.com/in/opeyemi-quadir-a670a7212/",
    },
    {
      name: "Dr Sadiat Adetoro Salau",
      university: "Federal University of Technology, Minna",
      link: "https://www.linkedin.com/in/sadiat-adetoro-salau/",
    },
    {
      name: "Mrs Benedicta Uchendu",
      university: "Ignatius Ajuru University of Education",
      link: "https://www.linkedin.com/in/benedicta-uchendu-619843147/",
    },
    {
      name: "Dr Basiru Adetomiwa",
      university: "Redeemers University, Ede",
      link: "https://www.linkedin.com/in/basiru-adetomiwa-31b558150/",
    },
    {
      name: "Dr Sophia Adeyeye",
      university: "Lead City University, Ibadan",
      link: "https://www.linkedin.com/in/sophia-adeyeye-92a621a0/",
    },
    {
      name: "Mrs Ify Obim",
      university: "University of Nigeria, Nsukka",
      link: "https://www.linkedin.com/in/ify-obim-85883772/",
    },
    {
      name: "Mrs Tihyaunin Dang",
      university: "University of Jos",
    },
    {
      name: "Dr Taiwo Ajayi",
      university: "Lagos State University of Science & Technology",
    },
  ];

  const Outcomes = [
    {
      title: "Lorem Ipsum dolor sit",
      description:
        "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nemo numquam accusantium saepe quos eaque ut doloribus praesentium. Deserunt corrupti sunt alias consectetur",
      thumbnail: "/assets/images/ecrnaija/ecr1.jpg",
      link: "",
    },
    {
      title: "Lorem Ipsum dolor sit",
      description:
        "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nemo numquam accusantium saepe quos eaque ut doloribus praesentium. Deserunt corrupti sunt alias consectetur",
      thumbnail: "/assets/images/ecrnaija/ecr2.jpg",
      link: "",
    },
  ];

  return (
    <>
      <div>
        <HeaderBanner />
      </div>
      <div className="container mx-auto ">
        <section className="py-8">
          <h2 className="text-center text-xxxl font-semibold text-foreground mb-4">
            ECR Network Nigeria
          </h2>
          <p className="text-sm text-foreground mt-4 mb-2">
            The Early Career Researchers (ECRs) team is an inclusive,
            community-led, Nigerian open science advocacy and implementation
            group championing discussions and engagements around open science
            with key national and institutional stakeholders.
          </p>

          <p className="text-sm text-foreground mb-2">
            The group, in collaboration with the Association of University
            Librarians of Nigerian Universities and the LIBSENSE network of
            experts, have come out with several outputs, such as a new common
            repository{" "}
            <Link
              className="text-primary"
              to="https://docs.google.com/spreadsheets/d/14DftpDbWuhe81Vr0VUZCpWLfR6kwCzjl/edit?gid=1808480562#gid=1808480562"
              target="_blank"
            >
              metadata schema
            </Link>
            , a call to{" "}
            <Link
              to="https://zenodo.org/records/10710011"
              target="_blank"
              className="text-primary"
            >
              action on assessment systems
            </Link>{" "}
            and work plans derived from a recent{" "}
            <Link
              to="https://indico.wacren.net/event/186/"
              target="_blank"
              className="text-primary"
            >
              research data management (RDM) workshop
            </Link>
            . We presented these{" "}
            <Link
              to="https://indico.wacren.net/event/195/attachments/635/861/Model%20Open%20Science%20Policy%20of%20Nigeria.pdf"
              target="_blank"
              className="text-primary"
            >
              outputs
            </Link>{" "}
            at priority-setting leadership engagement meetings in Lagos and
            Abuja.
          </p>
          <p className="text-sm text-foreground mb-2">
            Open Science-related activites for faculty members and postgraduate
            students are held on our various campuses.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-lg font-semibold text-foreground mb-4 text-left">
            Team Members
          </h3>
          <ul className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {Team.map((expert, index) => (
              <li key={index}>
                {expert.link ? (
                  <Link
                    to={expert.link}
                    target="_blank"
                    className="text-sm text-primary"
                  >
                    {expert.name}
                  </Link>
                ) : (
                  <p className="text-sm text-foreground">{expert.name}</p>
                )}

                <p className="text-xs text-foreground">{expert.university}</p>
              </li>
            ))}
          </ul>

          <div className="mt-4">
            <Link
              to="https://groups.google.com/a/ren.africa/d/forum/libsense-ecr"
              target="_blank"
              className="text-primary"
            >
              Join Us
            </Link>
          </div>
        </section>

        <section className="mb-12">
          <h3 className="text-xl font-semibold text-foreground mb-4 text-left">
            Activities & Outcomes
          </h3>
          <div className="grid grid-cols-1 gap-8">
            {Outcomes.map((outcome, index) => (
              <div
                key={index}
                className="flex flex-col lg:flex-row overflow-hidden"
              >
                {/* Image section */}
                <div className="w-full lg:w-1/2 flex-shrink-0">
                  <img
                    src={outcome.thumbnail}
                    alt={outcome.title}
                    className="w-full h-auto rounded-md"
                  />
                </div>

                {/* Text section */}
                <div className="flex flex-col p-6 w-full lg:w-2/3">
                  <h4 className="text-sm font-medium mb-2 text-left text-foreground">
                    {outcome.title}
                  </h4>
                  <p className="text-xs text-foreground mb-4 text-left">
                    {outcome.description}
                  </p>
                  <button className="bg-primary text-primary-foreground py-2 px-4 rounded-md w-max text-left">
                    Read More
                  </button>
                </div>
              </div>
            ))}
          </div>
        </section>

        <div className="flex justify-around space-x-8 my-8">
          <Link
            to="https://photos.wacren.net/index.php?/category/76"
            target="_blank"
            className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
          >
            Photos
          </Link>
          <Link
            to="/"
            className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
          >
            Videos
          </Link>
        </div>
      </div>
    </>
  );
};

export default ECRNigeriaPage;
