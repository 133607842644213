import React from "react";
import { useLocation } from "react-router-dom";
import HeaderImage from "../../../components/HeaderImage";
import Workshop1 from "../../../components/workshop/workshop_1";
import Workshop2 from "../../../components/workshop/workshop_2";
import Workshop3 from "../../../components/workshop/workshop_3";
import Workshop4 from "../../../components/workshop/workshop_4";

const WorkshopDetail: React.FC = () => {
  const location = useLocation();
  const { eventData } = location.state || {};

  // Switch Statement to Render Specific Event Content
  const renderEventContent = (content: number) => {
    switch (content) {
      case 1:
        return <Workshop1 />;
      case 2:
        return <Workshop2 />;
      case 3:
        return <Workshop3 />;
      case 4:
        return <Workshop4 />;
      default:
        return <p>No content available for this event.</p>;
    }
  };

  //
  //UNESCO.jpg

  return (
    <>
      <HeaderImage />
      <section className="container mx-auto">
        {/* <h2 className="text-xxl font-bold text-foreground mb-4">
          {eventData?.title}
        </h2> */}
        <h2 className="mt-4 text-xxl font-bold text-foreground mb-4 relative before:content-['—'] before:text-primary before:mr-2">
          {eventData?.title}
        </h2>

        <div>{renderEventContent(eventData?.content)}</div>
      </section>
    </>
  );
};

export default WorkshopDetail;
