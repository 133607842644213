import React from "react";
import HeaderBanner from "../../components/Header";

const RDMPage: React.FC = () => {
  return (
    <>
      <div>
        <HeaderBanner />
      </div>
      <div className="container mx-auto ">
        <section className="py-8">
          <h2 className="text-center text-xxxl font-semibold text-foreground mb-4">
            LIBSENSE RDM Programme
          </h2>
          <p className="text-sm text-foreground mt-4 mb-2">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
            tempore quis, aperiam, omnis aspernatur quas est assumenda nisi odio
            molestiae, labore veniam perferendis voluptate deserunt ut ducimus
            velit incidunt laudantium! Fugiat quidem, quis earum hic adipisci
            commodi quas magnam tenetur harum cum fugit laboriosam
            necessitatibus molestiae deleniti consectetur voluptatum cumque,
            debitis minus aspernatur consequatur, voluptatem illo nesciunt esse
            iusto! Natus?
          </p>

          <p className="text-sm text-foreground">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores
            aut eligendi repudiandae voluptatem doloribus autem ea voluptas
            corporis pariatur fuga, est vitae libero exercitationem eos unde
            consequatur repellendus soluta. Quas. Adipisci quia tenetur dolore
            sed laborum ad, laudantium excepturi voluptas harum earum deleniti
            quo veniam dolorum ea laboriosam nobis quae? Suscipit enim
            voluptatem laborum ab deserunt dolor reprehenderit ut laudantium.
          </p>
        </section>
        <section className="mb-12">
          <h3 className="text-xl font-semibold text-foreground mb-4 text-left">
            Activities & Outcomes
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {[1, 2].map((expert, index) => (
              <div
                key={index}
                className="flex flex-col lg:flex-row bg-card shadow-md rounded-md overflow-hidden"
              >
                {/* Image section */}
                <div className="w-full lg:w-1/2 flex-shrink-0">
                  <div className="w-full bg-gray-200 h-full bg-cover"></div>
                </div>

                {/* Text section */}
                <div className="flex flex-col justify-center p-6 w-full lg:w-2/3">
                  <h4 className="text-sm font-medium mb-2 text-left text-foreground">
                    Lorem Ipsum dolor sit
                  </h4>
                  <p className="text-xs text-foreground mb-4 text-left">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Nemo numquam accusantium saepe quos eaque ut doloribus
                    praesentium. Deserunt corrupti sunt alias consectetur
                  </p>
                  <button className="bg-primary text-primary-foreground py-2 px-4 rounded-md w-max text-left">
                    Read More
                  </button>
                </div>
              </div>
            ))}
          </div>
        </section>

        <div className="flex justify-between my-8">
          <button className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary">
            Events
          </button>
          <button className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary">
            Photos
          </button>
          <button className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary">
            Videos
          </button>
        </div>
      </div>
    </>
  );
};

export default RDMPage;
