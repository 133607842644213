import React from "react";
import HeaderImage from "../HeaderImage";
import { Link } from "react-router-dom";

const RuforumPage: React.FC = () => {
  return (
    <>
      <HeaderImage />
      <section className="container mx-auto">
        <h2 className="mt-4 text-xxl font-bold text-foreground mb-4 relative before:content-['—'] before:text-primary before:mr-2">
          LIBSENSE-RUFORUM: Collaboration for Improved Agricultural Research
          Data Management in Africa
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 text-left text-sm text-foreground">
          <div>
            <p className="mb-4">
              LIBSENSE and the Regional Universities Forum for Capacity Building
              in Agriculture (RUFORUM) are working together in a broad framework
              to leverage digital technologies to advance knowledge discovery,
              use and sharing of agriculture research outputs, for informed
              decision-making and development in Africa.
            </p>
            <p className="mb-4">
              The cooperation is guided by a Memorandum of Understanding (MoU)
              signed between the West and Central African Research and Education
              Network (WACREN) and RUFORUM. The main objective of the
              cooperation is to enhance scientific and scholarly communication
              in Africa, through the AfricaConnect3 project and the LIBSENSE
              initiative.
            </p>

            <Link
              to="/assets/pdf/WACREN-RUFORUM-Announcement.pdf"
              target="_blank"
              className="hover:bg-primary-600 flex-none bg-primary px-4 py-2 text-primary-foreground my-10"
            >
              READ MOU
            </Link>
          </div>
          <div>
            <h3 className="text-lg font-semibold text-primary mb-4">
              <Link
                to="https://survey.wacren.net/index.php/516664"
                target="_blank"
              >
                Needs Assessment Survey
              </Link>
            </h3>
            <p className="mb-4">
              As part of a collaborative agreement between RUFORUM and LIBSENSE,
              this project will address some of RUFORUM’s ICT objectives by
              supporting contemporary platform development to enable
              agricultural research data sharing and management.
            </p>
            <p className="mb-10">
              The survey seeks to gather requirements for an open data
              repository through a survey of potential end users within the
              member institutions of RUFORUM.
            </p>
            <Link
              to="https://survey.wacren.net/index.php/516664"
              target="_blank"
              className="hover:bg-primary-600 flex-none bg-primary uppercase px-4 py-2 text-primary-foreground my-10"
            >
              Take Part in the survey
            </Link>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 text-left text-sm text-foreground">
          <div>
            <h3 className="text-lg font-semibold text-foreground my-10">
              Survey Validation Workshop
            </h3>

            <div className="flex items-center justify-center mb-14">
              <img
                className=" bg-cover"
                src="/assets/images/workshop/WORKSHOP-UPDATE.jpg"
                alt="workshop"
              />
            </div>

            <p className="mb-4">
              LIBSENSE and RUFORUM has held a workshop on the sidelines of the
              RUFORUM Annual General Meeting 2022. The AGM and the Workshop
              presented a fine opportunity to create more awareness of the
              strategic collaboration between parties and also chart the way
              forward for the collaboration for the benefit of the agricultural
              research and learning community across Africa. A key objective of
              the Workshop was held to validate the needs assessment survey
              instrument for the development of an open data and knowledge
              repository for the RUFORUM community. A key outcome was the
              identification of the need to provide more awareness about key
              concepts in the survey document.
            </p>

            <div className="flex justify-center space-x-4 mt-9 mb-20">
              <Link
                to="/assets/pdf/WACREN-RUFORUM-Concept-note_30112022-1.docx.pdf"
                target="_blank"
                className="border border-gray-400 text-gray-700 py-2 px-4 rounded-lg hover:text-primary"
              >
                Concept
              </Link>
              <Link
                to="/"
                target="_blank"
                className="border border-gray-400 text-gray-700 py-2 px-4 rounded-lg hover:text-primary"
              >
                Video
              </Link>
              <Link
                to="https://drive.wacren.net/index.php/apps/files/?dir=/ruforum&fileid=103422"
                target="_blank"
                className="border border-gray-400 text-gray-700 py-2 px-4 rounded-lg hover:text-primary"
              >
                Slides
              </Link>
            </div>
          </div>
          <div>
            <h3 className="text-lg font-semibold text-foreground my-10">
              Survey Validation Workshop
            </h3>

            <div className="flex items-center justify-center mb-14">
              <img
                className=" bg-cover"
                src="/assets/images/workshop/Workshop-Webinar-2.jpg"
                alt="workshop"
              />
            </div>

            <p className="mb-4">
              The aim of this webinar was to sensitize participants to key
              concepts related to open science and research data management
              which underpin the LIBSENSE-RUFORUM needs assessment survey for
              the development of an open data and knowledge repository for the
              RUFORUM community. The webinar provided a platform for launching
              the survey to the community.
            </p>
            <div className="flex justify-center space-x-4 mt-36 mb-20">
              <Link
                to="/assets/pdf/LIBSENSE-RUFORUM-Concept-note_20012023-1.pdf"
                target="_blank"
                className="border border-gray-400 text-gray-700 py-2 px-4 rounded-lg hover:text-primary"
              >
                Concept
              </Link>
              <Link
                to="https://youtu.be/vQXiMVdMgCA"
                target="_blank"
                className="border border-gray-400 text-gray-700 py-2 px-4 rounded-lg hover:text-primary"
              >
                Video
              </Link>
              <Link
                to="https://drive.wacren.net/index.php/s/iwjiTy3QPcYpXEM"
                target="_blank"
                className="border border-gray-400 text-gray-700 py-2 px-4 rounded-lg hover:text-primary"
              >
                Slides
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RuforumPage;
