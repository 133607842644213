import React from "react";
import { NavLink } from "react-router-dom";

const GroupsButton: React.FC = () => {
  const buttons = [
    {
      label: "Projects & Initiatives",
      link: "/about-us/project-initiatives",
    },
    {
      label: "Working Groups",
      link: "/about-us/working-groups",
    },
    {
      label: "Partners",
      link: "/about-us/collaborations",
    },
    {
      label: "Impact",
      link: "/about-us/impact",
    },
  ];

  return (
    <div className="container mx-auto my-40">
      {/* <h2 className="text-left text-xxl font-semibold text-foreground uppercase mb-6">
        Get Involved
      </h2> */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {buttons.map((button, index) => (
          <NavLink
            key={index}
            to={button.link}
            className={({ isActive }) =>
              `block text-center border border-foreground px-6 py-5 rounded-md text-foreground transition duration-300 ${isActive ? "text-foreground" : "hover:text-primary"}`
            }
          >
            {button.label}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default GroupsButton;
