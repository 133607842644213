import React from "react";
import { Link } from "react-router-dom";

const Workshop1: React.FC = () => {
  return (
    <>
      <p className="text-sm text-foreground mb-4">
        This{" "}
        <Link
          target="_blank"
          to="https://events.ubuntunet.net/event/65/"
          className="text-primary"
        >
          Policy Development Workshop{" "}
        </Link>
        was the first in a series of workshops organised in collaboration with
        UNESCO and regional associations of higher education institutions in
        Africa to promote the development of open science policies at the
        institutional level to complement the LIBSENSE National Open Science
        Roadmaps. It was held as part of the regional annual conference of the
        Ubuntunet Alliance (a regional REN implementing the AfricaConnect3
        project in Southern and Eastern Africa).
      </p>
      <p className="text-sm text-foreground mb-4">
        The workshop's objective was to establish common ground on how to move
        from the high-level goals and principles articulated in the UNESCO
        recommendations on Open Science to the role of institutions in
        implementing these policies.
      </p>
      <p className="text-sm text-foreground mb-4">
        The workshop featured case studies of open science and open access
        policy development initiatives across Africa to stimulate discussion
        about what works and does not in such policy development and
        implementation initiatives. The event included a talk on localising
        research assessment in the context of open science.
      </p>
      <p className="text-sm text-foreground mb-4">
        In attendance were Deputy vice-chancellors in charge of research, head
        librarians, and deans in charge of early career researcher development
        (e.g., postgraduate researchers, PhDs, postdoctoral fellows, new
        lecturing staff).
      </p>

      <div className="flex items-center justify-center mb-14">
        <img
          className=" bg-cover"
          src="/assets/images/workshop/workshop1.jpeg"
          alt="workshop"
        />
      </div>
    </>
  );
};

export default Workshop1;
