import React from "react";
import { Link } from "react-router-dom";
import HeaderImage from "../../components/HeaderImage";

// Parent Component for all Working Groups
const WorkingGroups: React.FC = () => {
  return (
    <>
      <HeaderImage />

      <div className="max-w-4xl mx-auto mb-12 p-4">
        <h2 className="text-center text-xxl font-bold text-foreground mb-8">
          Working Groups
        </h2>
        <p className="text-sm text-foreground mb-1">
          To achieve LIBSENSE objectives, a key activity was the wide-ranging
          survey undertaken from September 2018 - March 2019, in each NREN
          region, and based on the questionnaire on evolving roles of HEI
          librarians that was piloted in WACREN. The survey was reframed within
          the context of the development and maintenance of federated Open
          Access repositories.
        </p>
        <p className="text-sm text-foreground mb-4">
          In each region, the survey aimed to:
        </p>

        <div className="space-y-8 mb-8">
          <PolicyWorkingGroup />
          <InfrastructureWorkingGroup />
          <CapacityBuilding />
        </div>
        <Link
          to="https://groups.google.com/a/ren.africa/g/libsense-discuss"
          target="_blank"
          className="block text-center border border-foreground mb-4 px-6 py-4 rounded-md text-foreground transition duration-300 hover:text-primary"
        >
          Apply to join a working Group
        </Link>
      </div>
    </>
  );
};

// Individual Working Group Components
const PolicyWorkingGroup: React.FC = () => {
  return (
    <div className="border rounded-lg p-6 bg-card flex gap-4 items-start transition-transform transform hover:scale-105 hover:bg-background hover:shadow-lg hover:border-text-primary duration-300">
      <img
        src="/assets/images/check.png"
        alt="check-mark"
        className="h-10 aspect-1"
      />
      <div>
        <h3 className="text-lg font-semibold text-foreground mb-4">
          Policy Working Group
        </h3>
        <p className="text-sm text-foreground mb-4">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
          ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua.
        </p>
      </div>
    </div>
  );
};

const InfrastructureWorkingGroup: React.FC = () => {
  return (
    <div className="border rounded-lg p-6 bg-card flex gap-4 items-start transition-transform transform hover:scale-105 hover:bg-background hover:shadow-lg duration-300">
      <img
        src="/assets/images/check.png"
        alt="check-mark"
        className="h-10 aspect-1"
      />
      <div>
        <h3 className="text-lg font-semibold text-foreground mb-4">
          Infrastructure Working Group
        </h3>
        <p className="text-sm text-foreground mb-4">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
          ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua.
        </p>
      </div>
    </div>
  );
};

const CapacityBuilding: React.FC = () => {
  return (
    <div className="border rounded-lg p-6 bg-card flex gap-4 items-start transition-transform transform hover:scale-105 hover:bg-background hover:shadow-lg duration-300">
      <img
        src="/assets/images/check.png"
        alt="check-mark"
        className="h-10 aspect-1"
      />
      <div>
        <h3 className="text-lg font-semibold text-foreground mb-4">
          Capacity Building
        </h3>
        <p className="text-sm text-foreground mb-4">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
          ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua.
        </p>
      </div>
    </div>
  );
};

export default WorkingGroups;
