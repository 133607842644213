import React from "react";
import { useTheme } from "../context/ThemeContext";
import { FaSun, FaMoon, FaTextHeight, FaUndo } from "react-icons/fa";

const themeColors = [
  { name: "pink", code: "#e11d48" },
  { name: "yellow", code: "#f59e0b" },
  { name: "green", code: "#22c55e" },
  { name: "blue", code: "#3b82f6" },
  { name: "orange", code: "#ea580c" },
  { name: "red", code: "#cc0022" },
  { name: "violet", code: "#6d28d9" },
];

const ThemeToggle: React.FC = () => {
  const {
    toggleThemeMode,
    setThemeColor,
    increaseTextSize,
    decreaseTextSize,
    toggleUnderlineLinks,
    toggleReadableFont,
    resetTheme,
  } = useTheme();

  return (
    <div className="p-4 bg-background">
      <div className="mb-4">
        <span className="text-xs font-semibold text-foreground">Color</span>
        <div className="mt-2 grid grid-cols-2 gap-2">
          {themeColors.map((color) => (
            <div
              key={color.name}
              onClick={() => setThemeColor(color.name)}
              className="focus-visible:ring-ring inline-flex px-3 h-8 cursor-pointer items-center justify-start whitespace-nowrap rounded-md border border-border bg-background text-xs font-medium text-muted-foreground shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-1 disabled:pointer-events-none disabled:opacity-50 hover:bg-card hover:text-foreground"
            >
              <span
                style={{ backgroundColor: color.code }}
                className="flex h-5 w-5 shrink-0 -translate-x-1 items-center justify-center rounded-full bg-rose-500"
              ></span>
              <p className="capitalize">{color.name}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="my-2">
        <span className="text-xs font-semibold text-foreground">Mode</span>
        <div className="mt-2 flex items-center gap-2">
          <button
            onClick={() => toggleThemeMode("light")}
            className="focus-visible:ring-ring inline-flex h-8 cursor-pointer items-center justify-start whitespace-nowrap rounded-md border border-border bg-background px-6 text-xs font-medium text-muted-foreground shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-1 disabled:pointer-events-none disabled:opacity-50 hover:bg-card hover:text-foreground"
          >
            <FaSun className="mr-1 h-5 w-5 text-muted-foreground/50" />
            Light
          </button>
          <button
            onClick={() => toggleThemeMode("dark")}
            className="focus-visible:ring-ring inline-flex px-6 h-8 cursor-pointer items-center justify-start whitespace-nowrap rounded-md border border-border bg-background text-xs font-medium text-muted-foreground shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-1 disabled:pointer-events-none disabled:opacity-50 hover:bg-card hover:text-foreground"
          >
            <FaMoon className="mr-1 h-5 w-5 text-muted-foreground/50" />
            Dark
          </button>
        </div>
      </div>

      <div className="my-2">
        <span className="text-xs font-semibold text-foreground">
          Accessibility Tools
        </span>
        <div className="mt-2 grid grid-cols-1 gap-2">
          <button
            onClick={() => increaseTextSize()}
            className="focus-visible:ring-ring inline-flex h-8 cursor-pointer items-center justify-start whitespace-nowrap rounded-md border border-border bg-background px-3 text-xs font-medium text-muted-foreground shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-1 disabled:pointer-events-none disabled:opacity-50 hover:bg-card hover:text-foreground"
          >
            <FaTextHeight className="mr-1 h-5 w-5 text-muted-foreground/50" />
            Increase Text
          </button>
          <button
            onClick={() => decreaseTextSize()}
            className="focus-visible:ring-ring inline-flex h-8 cursor-pointer items-center justify-start whitespace-nowrap rounded-md border border-border bg-background px-3 text-xs font-medium text-muted-foreground shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-1 disabled:pointer-events-none disabled:opacity-50 hover:bg-card hover:text-foreground"
          >
            <FaTextHeight className="mr-1 h-5 w-5 text-muted-foreground/50" />
            Decrease Text
          </button>

          <button
            onClick={() => toggleUnderlineLinks()}
            className="focus-visible:ring-ring inline-flex h-8 cursor-pointer items-center justify-start whitespace-nowrap rounded-md border border-border bg-background px-6 text-xs font-medium text-muted-foreground shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-1 disabled:pointer-events-none disabled:opacity-50 hover:bg-card hover:text-foreground"
          >
            Underline Links
          </button>

          <button
            onClick={() => toggleReadableFont()}
            className="focus-visible:ring-ring inline-flex h-8 cursor-pointer items-center justify-start whitespace-nowrap rounded-md border border-border bg-background px-6 text-xs font-medium text-muted-foreground shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-1 disabled:pointer-events-none disabled:opacity-50 hover:bg-card hover:text-foreground"
          >
            Readable Font
          </button>

          <button
            onClick={() => resetTheme()}
            className="focus-visible:ring-ring inline-flex h-8 cursor-pointer items-center justify-start whitespace-nowrap rounded-md border border-border bg-background px-6 text-xs font-medium text-muted-foreground shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-1 disabled:pointer-events-none disabled:opacity-50 hover:bg-card hover:text-foreground"
          >
            <FaUndo className="mr-1 h-5 w-5 text-muted-foreground/50" />
            Reset
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThemeToggle;
