import React from "react";
import NewsSection from "./Media/News";

const Media: React.FC = () => {
  return (
    <div>
      <NewsSection />
    </div>
  );
};

export default Media;
