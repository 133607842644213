import React from "react";
import SharedRepositories from "../../components/SharedRepositories";
import HeaderImage from "../../components/HeaderImage";

const OpenData: React.FC = () => {
  // const handleDocumentClick = () => {
  //   console.log("Documents clicked");
  // };

  return (
    <>
      <HeaderImage />

      <div className="min-h-screen ">
        <SharedRepositories
          title="National Multi-tenanted Repository"
          paragraphs={[
            "LIBSENSE’s goal of developing shared national repositories across the WACREN region aligns closely with the capabilities of the WEKO3 multitenanted repository developed by the National Informatics Institute (NII) of Japan. Therefore, WACREN, through LIBSENSE is collaborating closely with NII to leverage the capabilities of WEKO3 to extend the infrastructure to libraries and academic institutions in the region.",
            "The multitenancy feature of WEKO3 allows multiple institutions in the WACREN region to host their individual repositories on a shared infrastructure. It reduces costs and streamlines resource sharing among institutions in the region. WEKO3’s flexibility in customization allows institutions to adapt the repository to specific institutional and regional requirements. LIBSENSE is leveraging this to ensure that each member institution within WACREN can configure their repository interface, metadata standards, and workflows to match their research needs, while still benefiting from a shared, centralized infrastructure.",
          ]}
          // buttons={[
          //   {
          //     label: "Read More",
          //     onClick: handleDocumentClick,
          //     variant: "default",
          //   },
          // ]}
        />
      </div>
    </>
  );
};

export default OpenData;
