import React from "react";
import WhatWeDo from "../../components/ReusableData";
import HeaderImage from "../../components/HeaderImage";

const CapacityBuildingPage: React.FC = () => {
  const sections = ["Institutional", "National", "Regional", "Continental"];
  const content = {
    Institutional:
      "Content for the Institutional section. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    National:
      "Content for the National section. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    Regional:
      "Content for the Regional section. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    Continental:
      "Content for the Continental section. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  };

  return (
    <>
      <HeaderImage />

      <WhatWeDo
        sections={sections}
        content={content}
        title="Capacity Building"
        paragraphs={[
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus recusandae, voluptatibus temporibus autem voluptatum fugiat saepe? Dolor quo corrupti rerum quod in ea obcaecati ipsum, maiores perferendis. Facilis, tenetur explicabo?",
        ]}
      />
    </>
  );
};

export default CapacityBuildingPage;
