import React from "react";
import { Link } from "react-router-dom";

const Workshop3: React.FC = () => {
  return (
    <>
      <h2 className="mt-4 text-sm font-semibold text-foreground mb-4">
        Regional Policy Workshop 3 in Abuja
      </h2>
      <p className="text-sm text-foreground mb-4">
        LIBSENSE recognises active university leadership engagement and
        involvement are crucial to fostering African research excellence.
        Leadership greatly influences the quality of research ethics, principles
        and practices. It is in this context that LIBSENSE is collaborating with
        the Committee of Vice-Chancellors of Nigerian Universities (CVCNU), the
        Association of Librarians of Nigerian Universities (ALNU) and the
        participation of the Vice Chancellors Ghana (VCG) to organise the third
        LIBSENSE Leadership Policy Development Workshop, following successes in
        Abidjan and Botswana.
      </p>
      <p className="text-sm text-foreground mb-4">
        This Workshop, with financial support from the AfricaConnect3 project
        (co-funded by the European Commission), will deepen collaboration among
        leaders in higher education, regulators, and research funders within
        Nigeria and the WACREN region to elevate research standards and their
        impact.
      </p>
      <p className="text-sm text-foreground mb-4">
        The workshop will highlight recent policy advancements in the region and
        explore opportunities for national and international cooperation in
        higher education. It aims to improve understanding of digital object
        identifiers (DOIs) and alternative persistent identifiers (PIDs) like
        ARKs for research outputs. Additionally, it will address leadership in
        promoting research excellence and recommend establishing a sustainable
        research ecosystem.
      </p>
      <Link
        to="https://indico.wacren.net/event/212/"
        target="_blank"
        className="text-primary"
      >
        Click to see Workshop details.
      </Link>

      <div className="mt-4 flex items-center justify-center mb-14">
        <img
          className=" bg-cover"
          src="/assets/images/workshop/workshop3.jpg"
          alt="workshop"
        />
      </div>
    </>
  );
};

export default Workshop3;
