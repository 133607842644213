import React from "react";
import { NavLink } from "react-router-dom";
import { FaTwitter } from "react-icons/fa";
// import { useTheme } from "../../context/ThemeContext";
import ChatDialog from "../Chat/ChatDialog";

const Footer: React.FC = () => {
  const year = new Date().getFullYear();

  // const [isChatOpen, setIsChatOpen] = useState(false);

  return (
    <footer className="bg-card text-foreground">
      <div className="flex flex-col items-center justify-center min-w-[18rem] w-full bg-card p-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 w-full max-w-7xl">
          <div className="flex flex-col items-start">
            <NavLink
              to="/"
              aria-label="Home"
              className="flex items-center mb-4"
            >
              <img
                src="/assets/images/libsense.png"
                className="h-[160px]"
                alt="Libsense Logo"
              />
            </NavLink>
            <ul className="space-y-1">
              <li className="text-muted-foreground">
                <NavLink
                  to="/privacy"
                  className={({ isActive }) =>
                    isActive
                      ? "text-primary inline-flex py-2 text-sm font-medium"
                      : "text-muted-foreground/50 hover:text-muted-foreground inline-flex py-2 text-sm font-medium"
                  }
                >
                  Privacy
                </NavLink>
              </li>
              <li className="text-muted-foreground">
                <NavLink
                  to="/disclaimer"
                  className={({ isActive }) =>
                    isActive
                      ? "text-primary inline-flex py-2 text-sm font-medium"
                      : "text-muted-foreground/50 hover:text-muted-foreground inline-flex py-2 text-sm font-medium"
                  }
                >
                  Disclaimer
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="flex flex-col">
            <h2 className="text-muted-foreground font-semibold">
              About LIBSENSE
            </h2>
            <ul className="flex flex-col space-y-1 mt-2">
              <li className="text-muted-foreground/50 hover:text-muted-foreground inline-flex py-2 text-sm font-medium">
                LIBSENSE is a pan-African, community-led initiative to advance
                open science in Africa
              </li>
            </ul>
          </div>

          <div className="flex flex-col">
            <h2 className="text-muted-foreground font-semibold">
              Get in touch
            </h2>
            <ul className="flex flex-col space-y-1 mt-2">
              <li className="text-muted-foreground/50 hover:text-muted-foreground inline-flex py-2 text-sm font-medium">
                West and Central African Research and Education Network,
              </li>
              <li className="text-muted-foreground/50 hover:text-muted-foreground inline-flex py-2 text-sm font-medium">
                VCG Office Complex, IPS Road
              </li>
              <li className="text-muted-foreground/50 hover:text-muted-foreground inline-flex py-2 text-sm font-medium">
                P O Box LG 1279,
              </li>
              <li className="text-muted-foreground/50 hover:text-muted-foreground inline-flex py-2 text-sm font-medium">
                Accra, Ghana
              </li>
              <li className="text-muted-foreground/50 hover:text-muted-foreground inline-flex py-2 text-sm font-medium">
                Tel: +233 302942873
              </li>
            </ul>
          </div>

          <div className="flex flex-col">
            <h2 className="text-muted-foreground font-semibold">
              Important links
            </h2>
            <ul className="flex flex-col space-y-1 mt-2">
              <li className="text-muted-foreground">
                <NavLink
                  to="/about-us"
                  className={({ isActive }) =>
                    isActive
                      ? "text-primary inline-flex py-2 text-sm font-medium"
                      : "text-muted-foreground/50 hover:text-muted-foreground inline-flex py-2 text-sm font-medium"
                  }
                >
                  About LIBSENSE
                </NavLink>
              </li>
              <li className="text-muted-foreground">
                <NavLink
                  to="/lastest"
                  className={({ isActive }) =>
                    isActive
                      ? "text-primary inline-flex py-2 text-sm font-medium"
                      : "text-muted-foreground/50 hover:text-muted-foreground inline-flex py-2 text-sm font-medium"
                  }
                >
                  Latest news
                </NavLink>
              </li>
              <li className="text-muted-foreground">
                <NavLink
                  to="/upcomin"
                  className={({ isActive }) =>
                    isActive
                      ? "text-primary inline-flex py-2 text-sm font-medium"
                      : "text-muted-foreground/50 hover:text-muted-foreground inline-flex py-2 text-sm font-medium"
                  }
                >
                  Upcoming events
                </NavLink>
              </li>
              <li className="text-muted-foreground">
                <NavLink
                  to="/involve"
                  className={({ isActive }) =>
                    isActive
                      ? "text-primary inline-flex py-2 text-sm font-medium"
                      : "text-muted-foreground/50 hover:text-muted-foreground inline-flex py-2 text-sm font-medium"
                  }
                >
                  Get involved
                </NavLink>
              </li>
            </ul>
          </div>
        </div>

        <div className="flex flex-wrap justify-center items-center gap-4 mt-8 p-4 w-full max-w-7xl">
          <p className="text-muted-foreground/50 hover:text-muted-foreground inline-flex py-2 text-sm font-medium">
            The LIBSENSE initiative is supported by the AfricaConnet project
          </p>

          <img
            src="/assets/images/AC3 logo.png"
            alt="AC3 Project Logo"
            className="h-12"
          />
          <img
            src="/assets/images/EU logo.png"
            alt="EC Logo"
            className="h-12"
          />
          <div className="ml-auto flex gap-2">
            <NavLink to="#" aria-label="Twitter">
              <FaTwitter className="text-xxxl text-muted-foreground mr-2" />
            </NavLink>
            {/* <NavLink
              to="#"
              aria-label="Chat"
              onClick={() => setIsChatOpen(true)}
            >
              <img
                src={
                  theme.mode === "light"
                    ? "/assets/icons/bubble-chat.png"
                    : "/assets/icons/chat.png"
                }
                alt="Chat"
                className="h-8"
              />
            </NavLink> */}
          </div>
        </div>

        <div className="mt-4 text-center text-muted-foreground">
          Copyright {year}. LIBSENSE. All rights reserved
        </div>
      </div>
      <ChatDialog />
    </footer>
  );
};

export default Footer;
