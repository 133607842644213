import React, { useState } from "react";
import { NavLink } from "react-router-dom";

interface NavItems {
  sections: string[];
  content: { [key: string]: any };
  title: string;
  paragraphs?: string[];
}

// Reusable SideNavigation Component
const WhatWeDo: React.FC<NavItems> = ({
  sections,
  content,
  title,
  paragraphs,
}) => {
  const [selectedSection, setSelectedSection] = useState(sections[0]);

  return (
    <div className="max-w-6xl mx-auto my-8 p-4">
      {/* Title */}
      <h3 className="text-center text-lg font-semibold text-foreground mb-4">
        {title}
      </h3>

      {/* Description */}
      <div className="space-y-4 mb-8">
        {paragraphs &&
          paragraphs.map((paragraph, index) => (
            <p key={index} className="text-sm text-foreground">
              {paragraph}
            </p>
          ))}
      </div>

      {/* Divider */}
      <hr className="border-t border-gray-300 mb-8" />

      {/* Content Sections */}
      <div className="flex">
        {/* Left Side Navigation */}
        <div className="w-1/4 space-y-4 font-semibold">
          {sections.map((section) => (
            <NavLink
              key={section}
              to="#"
              onClick={() => setSelectedSection(section)}
              className={`block ${selectedSection === section ? "text-primary" : "text-foreground"}`}
            >
              {section}
            </NavLink>
          ))}
        </div>

        {/* Right Side Content */}
        <div className="w-3/4 space-y-4 text-gray-700">
          {content[selectedSection]}
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
