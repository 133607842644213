import React, { useState } from "react";
import HeaderImage from "../../components/HeaderImage";

const NetworkOfExperts: React.FC = () => {
  const [expandedFAQ, setExpandedFAQ] = useState<number | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedExpert, setSelectedExpert] = useState<any>(null);

  const toggleFAQ = (index: number) => {
    // Toggle the selected FAQ, collapsing it if it's already expanded
    setExpandedFAQ(expandedFAQ === index ? null : index);
  };

  const experts = [
    {
      name: "Naniki Maphakwane",
      brief_description:
        "Naniki Maphakwane is the Director of Library and Information Services at Botswana Open University (BOU) responsible for leading five regional library centre services. Her job is to provide professional leadership in the delivery of library and information services at the BOU.",
      thumbnail: "/assets/images/experts/Naniki.png",
      details:
        "Naniki Maphakwane is the Director of Library and Information Services at Botswana Open University (BOU) responsible for leading five regional library centre services. Her job is to provide professional leadership in the delivery of library and information services at the BOU.Naniki is also the Chairperson of the Library Consortium and is responsible for the provision of leadership for the consortium which comprises 58 member institutions. She is responsible for overseeing the various focus areas of the consortium which are: Electronic databases subscriptions, Open Access and Open Science Advocacy, Public Library Innovation (promoting information technology in public libraries), Open-Source Software (promotion of uptake of information technologies in libraries) and Training and Capacity building.She has more than thirty years of experience working in various types of libraries including public, secondary school, and higher education libraries where she undertook various responsibilities like customer services, technical services metadata creation and information technology support. Currently Naniki holds a Master’s Degree in Library and Information Studies obtained from the University of Botswana.",
      details_flag: "/assets/images/flags/flag-botswana-scaled.jpg",
    },
    {
      name: "Jonas Redwood-Sawyerr",
      brief_description:
        "Ing. Professor Jonas A.S. Redwood-Sawyerr is the current Chairman of the National Science Technology and Innovation Council (NSTIC), and the Chairman of the Sierra Leone Research and Education Network (SLREN). He is also a member of the Task Force for the Open Science initiative in Sierra Leone.",
      thumbnail:
        "/assets/images/experts/Professor-Jonas-A.S.-Redwood-Sawyerr.png",
      details: `Ing. Professor Jonas A.S. Redwood-Sawyerr is the current Chairman of the National Science Technology and Innovation Council (NSTIC), and the Chairman of the Sierra Leone Research and Education Network (SLREN). He is also a member of the Task Force for the Open Science initiative in Sierra Leone.
He is the Immediate Past President of the Sierra Leone Institution of Engineers and a member of the Professional Engineers Registration Council. He is the current President of the West African Federation of Engineering Organisations (WAFEO). He is also the immediate Past Vice President of the West Africa Federation of Engineering Organisation (WAFEO) and current Chairman of the WAFEO sub-committee on the harmonization of Engineering Education and professional training.
Prof. Redwood-Sawyerr has undertaken consultancies in some areas including energy, power sector reform, Energy policy formulation, Technical Education and Information Technology, and the Education For All (EFA) Capacity Building UNESCO project.
He obtained his schooling at the Prince of Wales School, Freetown, Sierra Leone. His academic and professional qualifications are as follows.
Bachelor of Engineering (Electrical Engineering option), University of Sierra Leone; Master of Science (Industrial Electronics), University of London; Doctor of Philosophy (Electrical Systems Engineering), University of Essex, UK.
He joined the University as a Research and Teaching Assistant in the then Department of Electrical Engineering, Fourah Bay College, University of Sierra Leone in 1975. He was appointed Lecturer in the same Department in 1978 and Senior Lecturer in 1988. He became the substantive Head of the Department of Electrical & Electronic Engineering from 1991 up to 2004 and served as Dean of the Faculty of Engineering from 2001 to 2003. He was promoted to the rank of Associate Professor in 2001 and Full Professor in 2008. He served as the Acting Principal of Fourah Bay College, University of Sierra Leone, from December 2003 to July 2005, and the Dean of the Board of Postgraduate Studies from 2000 to 2003. He was the First Deputy Vice-Chancellor of the University of Sierra Leone Fourah Bay College from 2005 to 2009. He served as the Acting Vice-Chancellor and Principal of the University of Sierra Leone from August 2009 to August 2011 and as the substantive Vice-Chancellor and Principal of the University of Sierra Leone from 1st September 2011 to 3rd September 2013.
Professor Redwood-Sawyerr was a member of the Editorial Board of the Journal of Pure and Applied Science. He has presented papers in many conferences, workshops, and seminars and has research publications in many international Journals and Proceedings of Conferences. He has also attended several continuing professional development programs in telecommunications, information technology, energy, power sector reforms, technology management, and renewable energy with special attention to solar (photovoltaic) energy and Bio-energy, and technical and vocational education.
His specialist area is in spectral efficiency of communications systems and he has co-authored a book ‘Telecommunications in Sierra Leone; from relays to microprocessor switching’, which was published in Germany. He also has research interests in Engineering education and training, gender and energy issues, renewable energy, power sector reforms, science and technology, and information and communications technology. His most recent casual book publication ‘Aligning Quality Assurance and Graduate Employability,’ was drawn from his research on the mismatch of university training and the requirements of employers of university graduates.
He served as the Chairman of the West African Examinations Council (WAEC) from 2009 to 2012.
      `,
      details_flag: "/assets/images/flags/sierra-leone-flag-scaled.jpg",
    },
    {
      name: "Horácio Francisco",
      brief_description:
        "Horácio Francisco Zimba holds a degree in Library Science, a Master's, and a Ph.D. in Information Science, from the University of Brasília. He holds a postgraduate certificate in Systems Analysis, from the Catholic University of Brasilia.",
      thumbnail: "/assets/images/experts/Horacio-Francisco-Zimba.png",
      details:
        "Horácio Francisco Zimba holds a degree in Library Science, a Master’s, and a Ph.D. in Information Science, from the University of Brasília. He holds a postgraduate certificate in Systems Analysis, from the Catholic University of Brasilia.Since 2008 he has been working as Assistant Lecturer at, the School of Communication and Arts, teaching subjects related to Information and Communication Technologies Applied for Libraries and Documentation Services. From 2011 to the present he has been the Director of Documentation Services – Central Library, at Eduardo Mondlane University; where since 2012 he has been coordinating the project that aims to strengthen the capacity for access and usage of scientific information resources in Mozambique. This includes capacity building in terms of human resources, infrastructure, and strategies to ensure collaboration between University libraries through the Mozambican Association of Academic and Research Libraries (AMOBAP). He is a founder and current Chairperson of the Mozambican Association of Academic and Research Libraries, since 2018.",
      details_flag: "/assets/images/flags/mozambique-flag-scaled.jpg",
    },
    {
      name: "Tom Olyhoek",
      brief_description:
        "Tom Olyhoek has been living and working in Africa for many years. After obtaining his PhD in molecular microbiology from Amsterdam University (1982) he has been at the Max Planck Institute in Berlin for 7 years.",
      thumbnail: "/assets/images/experts/Tom-Olyhoek.png",
      details:
        "Tom Olyhoek has been living and working in Africa for many years. After obtaining his PhD in molecular microbiology from Amsterdam University (1982) he has been at the Max Planck Institute in Berlin for 7 years. He has spent seven years in Kenya and Algeria doing research on malaria, sleeping sickness, and meningococcal epidemics. In 2012 he started advocating for open access and open science as Open Access working group coordinator for Open Knowledge International.In 2013 he became a member of the (DOAJ (Directory of Open Access Journals) advisory board which was instrumental in redefining the criteria for being indexed in DOAJ. From 2015 till July 2023 he was Editor in Chief at the DOAJ. Starting July 2023 he has been Head of Outreach and Advocacy for DOAJ and Outreach consultant for DOAB, with a focus on Africa.Tom will leave DOAJ and DOAB by the end of 2023, and continue working as a private consultant on open science with African organizations and publishers.In terms of teaching experience, Tom has been a longtime member of the programming committee of Force11 where he has taught at the yearly Force11 Open Science Summer School on the topic of how to evaluate scientific quality for journals, articles, and individual scholars He has good language skills in Dutch, German, English and French. His current research interests are, copyright and licensing in open-access publishing, the development of new ways to assess the quality of scholars and scholarly works, and following research in the area of soil microbiology about soil health and human health (microbiome research).",
      details_flag: "",
    },
    {
      name: "Dr. Gordon Akon-Yamga",
      brief_description:
        "Dr. Gordon Akon-Yamga is a multidisciplinary research scientist at the CSIR-Science and Technology Policy Research Institute (CSIR-STEPRI). His work predominantly revolves around environment and natural resources governance, underpinned by extensive knowledge in science, technology, and innovation policy.",
      thumbnail: "/assets/images/experts/Gordon-Yamgs.png",
      details:
        "Dr. Gordon Akon-Yamga is a multidisciplinary research scientist at the CSIR-Science and Technology Policy Research Institute (CSIR-STEPRI). His work predominantly revolves around environment and natural resources governance, underpinned by extensive knowledge in science, technology, and innovation policy. He holds a Ph.D. in Philosophy from the University of North Texas and a Master of Philosophy in Environmental Science from the University of Ghana, supplementing his Bachelor of Arts in Geography and Resource Development from the same university.His proficiency is further enhanced through various training programs, such as the “Science, Technology, and Innovation Policy and Policy Instruments for SDGs” by UN-IATT WS6 Focal Points in 2020, the “Executive Leadership Programme in Monitoring and Evaluation” in 2021, and the “Collecting and Analysing R&D and Innovation Data” by AUDA-NEPAD in 2023. Dr. Akon-Yamga has been instrumental in significant projects, including CAAST-Net for EU-Africa collaboration in research and innovation, and currently coordinates the Horizon 2020-funded “WIDER UPTAKE” project in Ghana. He leads a project on the Distributional Impacts of Innovation and SME Support, funded by IDRC Canada. His efforts encompass strengthening Science, Technology, and Innovation systems for sustainable development in Africa and assessing the socioeconomic effects of initiatives like the Savannah Integrated Biodiversity Conservation Initiative.",
      details_flag: "/assets/images/flags/ghana-flag.jpg",
    },
    {
      name: "Ouattara-Coulibaly Cécile",
      brief_description:
        "Deputy Director in charge of Scientific Commissions at the Virtual University of Côte d'Ivoire. She has also been the Coordinator of technical activities of the Virtual Library of Higher Education and Scientific Research of Côte d'Ivoire since 2016, Cécile has been deputy director in charge of scientific commissions since 2017, at the Virtual University of Côte d'Ivoire (UVCI).",
      thumbnail: "/assets/images/experts/Cecile-Coulibaly.png",
      details:
        "Deputy Director in charge of Scientific Commissions at the Virtual University of Côte d’Ivoire. She has also been the Coordinator of technical activities of the Virtual Library of Higher Education and Scientific Research of Côte d’Ivoire since 2016, Cécile has been deputy director in charge of scientific commissions since 2017, at the Virtual University of Côte d’Ivoire (UVCI).Through the “Open Access Week Côte d’Ivoire” initiative, established in 2018, Cécile, national coordinator of EIFL programs, is engaged in open science activities. She is the national focal point for implementing the LIBSENSE open science program in Africa, led by WACREN. She also coordinated the activities of the National Symposium on Open Science, a pre-conference of the 7th Annual WACREN Conference, held in Abidjan from April 25 to 29, 2022.Cécile is secretary of the working group for the development of a national open science policy. She also leads the project for creating and implementing an ORCID Consortium in Côte d’Ivoire (2023-2024). Also involved in the LIBSENSE-Connect program for repositories infrastructure issues, Cécile is at the heart of an inter-university project (UVCI, UNA, UFHB, INPHB) for implementing open science in the fields of renewable energies, energy efficiency and climate change in partnership with GIZ in Côte d’Ivoire (2023-2025). This project is an opportunity to strengthen advocacy for open science and open access in scholarly publishing, research data and results promoting, policies and repositories. It will include the creation of a scientific journal, the assignment of DOI, and capacity building for researchers, developers, librarians and other communities. Cécile is also a member of the French-speaking OER working group and a board member of the African Open Science Platform (AOSP).Cécile is also a member of the French-speaking OER working group and a board member of the African Open Science Platform (AOSP).bus leo.",
      details_flag: "/assets/images/flags/cote-d-ivoire-flag.png",
    },
  ];

  const faq = [
    {
      question: "What is LIBSENSE Network of Experts?",
      answer:
        "For several years now, LIBSENSE has been actively involved in promoting open science in various African countries by focusing on infrastructure, policy development, and open science practices. Through these initiatives, LIBSENSE has built a network of knowledgeable individuals who are eager to share their expertise in open science with others across Africa. The goal is to further advance open science in our side of the world by building capacities through experts knowledge and experience sharing. These experts are proficient in different languages including English, French, Portuguese, and Arabic. They would provide guidance and support in areas including policy development, open access repositories, open access journals, research data management, and incentives.",
    },
    {
      question: "Who can join the Network of Experts?",
      answer:
        "Our network consists of a team of knowledgeable individuals with diverse backgrounds in the field of open science. These experts possess expertise in various areas such as policy, capacity building, infrastructure, governance, and legal matters. We highly value the diversity of their experiences and strive to ensure that our team reflects a wide range of subject areas, geographical locations, contexts, and cultures within the realm of open science. Applicants must take the above into consideration when they are applying to join the network.",
    },
    {
      question: "Who can benefit from the Network of Experts?",
      answer:
        "The Experts are accessible to university libraries, research institutes, publishers, editors, early-career researchers, library consortia, library management and any other individuals, groups or bodies that work towards implementing various aspects of open science within their institutions or countries. If you wish to consult with the Network of Experts, you can get in touch with us. The LIBSENSE secretariat will coordinate with you to connect you with the most suitable experts who can offer guidance and resources to assist you in finding solutions or developing strategies.",
    },
    {
      question: "LIBSENSE Network of Experts",
      answer:
        "Over the last several years, LIBSENSE has been working in several African countries to advance open science in infrastructure, policy development, and open science practices. Through these activities, LIBSENSE has cultivated a network of knowledgeable participants willing to share their expertise in open science with others in Africa. To support continued progress on open science and expand expertise across the continent, LIBSENSE is forming a network of experts composed of members who offer their time to help others with issues or questions. These experts, who speak different languages (English, French, Portuguese, Arabic), will provide guidance and support on policy development, open access repositories, open access journals, research data management, and incentives. The Network of Experts is available to universities, research institutes, and governments working on implementing one or more aspects of open science in their institution or country. Those who want to consult with the Network of Experts can contact us. The LIBSENSE secretariat will arrange for the most appropriate experts to meet with you and provide guidance/resources to help with solutions or strategies.",
    },
  ];

  const openModal = (expert: any) => {
    setSelectedExpert(expert);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedExpert(null);
  };

  return (
    <>
      <HeaderImage />
      <div className="container mx-auto lg:container">
        {/* Network of Experts Section */}
        <section className="mb-12 text-center">
          <h2 className="text-center text-xxl font-bold text-foreground mb-8">
            Network of Experts
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 text-left text-sm text-foreground">
            <p>
              LIBSENSE has been at the forefront of promoting open science in
              several African countries by focusing on infrastructure, policy
              development, and open science practices. Through these efforts, a
              network of knowledgeable participants has been established to
              facilitate the sharing of expertise in open science across Africa.
              To further this progress and expand expertise throughout the
              continent, LIBSENSE is creating a diverse Network of Experts
              proficient in English, French, Portuguese, and Arabic.
            </p>
            <p>
              This network will offer guidance and support on policy
              development, open access repositories, research data management,
              and more to universities, research institutes, and governments
              seeking to implement open science practices. Organizations
              interested in consulting with the Network of Experts can contact
              LIBSENSE for tailored guidance and resources.
            </p>
          </div>
        </section>

        {/* Experts Section */}
        <section className="mb-12 text-center">
          <h3 className="text-lg font-semibold text-foreground mb-4">
            Experts
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {experts.map((expert, index) => (
              <div
                key={index}
                className="flex flex-col lg:flex-row bg-card shadow-md rounded-md overflow-hidden"
              >
                {/* Image section */}
                <div className="w-full lg:w-1/2 flex-shrink-0">
                  <img
                    className="w-full h-full bg-cover"
                    src={expert.thumbnail}
                    alt={`profile of ${expert.name}`}
                  />
                </div>

                {/* Text section */}
                <div className="flex flex-col justify-center p-6 w-full lg:w-2/3">
                  <h4 className="text-sm font-medium mb-2 text-left text-foreground">
                    {expert.name}
                  </h4>
                  <p className="text-xs text-foreground mb-4 text-left">
                    {expert.brief_description}
                  </p>
                  <button
                    className="bg-primary text-primary-foreground py-2 px-4 rounded-md w-max text-left"
                    onClick={() => openModal(expert)}
                  >
                    Read More
                  </button>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Modal */}
        {showModal && selectedExpert && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 overflow-y-auto">
            <div className="bg-card w-11/12 md:w-2/3 lg:w-1/2 p-8 rounded-lg relative max-h-[80vh] overflow-y-auto">
              {/* Close Button */}
              <button
                onClick={closeModal}
                className="absolute top-4 right-4 text-xl font-bold text-red-500"
              >
                &times;
              </button>

              {/* Modal Content */}
              <div className="flex flex-col gap-4 md:flex-row">
                {/* Thumbnail */}
                <div className="w-full md:w-1/4 flex-shrink-0 mb-4 md:mb-0 md:mr-4">
                  <img
                    src={selectedExpert.thumbnail}
                    alt={selectedExpert.name}
                    className="w-full h-auto rounded-md"
                  />
                  <h2 className="text-lg font-semibold text-foreground mb-2">
                    {selectedExpert.name}
                  </h2>
                  <img
                    src={selectedExpert.details_flag}
                    alt="flag"
                    className="inline-block mr-2"
                  />
                </div>

                {/* Text Section */}
                {/* <div className="w-full">
                  <p className="text-sm text-foreground mb-4 first-letter:text-xxl first-letter:font-bold first-letter:text-primary first-letter:float-left first-letter:pr-2">
                    {selectedExpert.details}
                  </p>
                </div> */}
                {/* Text Section */}
                <div className="w-full">
                  <p className="text-sm text-foreground  mb-4">
                    <span
                      className="font-bold text-center text-primary float-left mr-2 leading-none"
                      style={{ fontSize: "50px", lineHeight: 1 }}
                    >
                      {selectedExpert.details.charAt(0)}
                    </span>
                    {selectedExpert.details.slice(1)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* FAQs Section */}
        <section className="text-center mb-8">
          <h3 className="text-2xl font-bold mb-6">FAQs</h3>
          <div className="space-y-4">
            {faq.map((faq, index) => (
              <div
                key={index}
                className="bg-primary text-primary-foreground p-4 rounded-md"
              >
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => toggleFAQ(index)}
                >
                  <p className="text-sm text-primary-foreground">
                    {faq.question}
                  </p>
                  <button className="text-xl font-bold">
                    {expandedFAQ === index ? "-" : "+"}
                  </button>
                </div>
                {expandedFAQ === index && (
                  <div className="mt-4 bg-card p-4 text-left text-foreground rounded-md">
                    <p className="text-sm text-foreground">{faq.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </section>
      </div>
    </>
  );
};

export default NetworkOfExperts;
