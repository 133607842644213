import React from "react";
import { NavLink } from "react-router-dom";

const ButtonGroup: React.FC = () => {
  const buttons = [
    {
      label: "Subscribe to our mailing list",
      type: "external",
      link: "https://groups.google.com/a/ren.africa/g/libsense-discuss",
    },
    {
      label: "Join the RDM Advocacy Group",
      type: "internal",
      link: "/rdm-advocacy",
    },
    {
      label: "Be a LIBSENSE country volunteer",
      type: "internal",
      link: "/volunteer",
    },
    {
      label: "Join our Network of Experts",
      type: "internal",
      link: "/network-of-experts",
    },
    {
      label: "Fund LIBSENSE activities",
      type: "internal",
      link: "/fund-activities",
    },
    {
      label: "For general correspondence",
      type: "internal",
      link: "/contact",
    },
  ];

  return (
    <div className="container mx-auto my-40">
      <h2 className="text-left text-xxl font-semibold text-foreground uppercase mb-6">
        Get Involved
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {buttons.map((button, index) =>
          button.type === "internal" ? (
            <NavLink
              key={index}
              to={button.link}
              className={({ isActive }) =>
                `block text-center border border-foreground px-6 py-3 rounded-md text-foreground transition duration-300 ${isActive ? "text-foreground" : "hover:text-primary"}`
              }
            >
              {button.label}
            </NavLink>
          ) : (
            <NavLink
              to={button.link}
              target="_blank"
              className="block text-center border border-foreground px-6 py-3 rounded-md transition duration-300 text-foreground hover:text-primary"
            >
              {button.label}
            </NavLink>
          )
        )}
      </div>
    </div>
  );
};

export default ButtonGroup;
