import React from "react";
import ButtonGroup from "../../components/InvolvedBtn";
import { Link } from "react-router-dom";
import HeaderImage from "../../components/HeaderImage";

const ProjectsInitiative: React.FC = () => {
  return (
    <>
      <HeaderImage />

      <div className="container mx-auto px-4 py-8">
        <h2 className="text-center text-xxl font-bold text-foreground mb-8">
          Projects & Initiatives
        </h2>
        {/* Section 1: Research Data Management */}
        <section className="mb-8">
          <h3 className="text-lg font-semibold text-foreground mb-4">
            Research Data Management
          </h3>
          <p className="text-sm text-foreground mb-2">
            Research Data Management (RDM) is an essential facet of any research
            project, encompassing the collection, storage, organisation,
            protection, and preservation of research data. It ensures the
            authenticity, integrity, and security of data throughout its
            lifecycle—from planning and acquisition to analysis, storage, and
            archiving. Effective data management and governance are not solely
            the researcher's responsibility; they should be ingrained in
            institutional structures, including policy frameworks, functions,
            processes, and infrastructures.
          </p>
          <p className="text-sm text-foreground mb-2">
            LIBSENSE has been building the capacity of early career researchers,
            librarians, and research administration staff in African
            universities and research institutions to develop the skills and
            infrastructure needed to manage, preserve, and share research data
            effectively. The initiative supports creating policies and
            frameworks for open science and RDM, promoting the adoption of FAIR
            (Findable, Accessible, Interoperable, Reusable) data principles
            across institutions.
          </p>
          <p className="text-sm text-foreground mb-4">
            These efforts advance African research, enhance global
            collaboration, and ensure that African researchers can effectively
            manage and share their data in line with international standards.
          </p>
          <Link
            to="/rdm"
            className="hover:bg-primary-600 flex-none bg-primary px-4 py-2 text-primary-foreground text-center"
          >
            Read more
          </Link>
        </section>
        {/* Section 2: Open Science  */}

        <section className="mb-8">
          <h3 className="text-lg font-semibold text-foreground mb-4">
            Open Science
          </h3>
          <p className="text-sm text-foreground mb-4">
            LIBSENSE is advancing open science across Africa through a
            multi-faceted approach. Its efforts focus on improving access to
            scientific knowledge and fostering a culture of collaboration and
            transparency in research. Key initiatives include building
            institutional open science capacity, national open science policies,
            governance and shared infrastructure, regional leadership policy
            engagement and continental open science infrastructure.
          </p>
          <div className="flex space-x-4">
            <Link
              to=""
              className="hover:bg-primary-600 flex-none bg-primary px-4 py-2 text-primary-foreground"
            >
              Institutional
            </Link>
            <Link
              to=""
              className="hover:bg-primary-600 flex-none bg-primary px-4 py-2 text-primary-foreground"
            >
              National
            </Link>
            <Link
              to=""
              className="hover:bg-primary-600 flex-none bg-primary px-4 py-2 text-primary-foreground"
            >
              Regional
            </Link>
            <Link
              to=""
              className="hover:bg-primary-600 flex-none bg-primary px-4 py-2 text-primary-foreground"
            >
              Continental
            </Link>
          </div>
        </section>
        {/* Section 3: LIBSENSE-Connect */}
        <section className="mb-8">
          <h3 className="text-lg font-semibold text-foreground mb-4">
            LIBSENSE-Connect
          </h3>
          <p className="text-sm text-foreground mb-2">
            LIBSENSE-Connect is a comprehensive and interactive program designed
            to empower African software and infrastructure developers with the
            knowledge and skills to embrace open science principles and
            practices. The program aims to foster a more inclusive and
            collaborative research ecosystem by building the technical
            capacities to develop infrastructure that promotes transparency,
            accessibility, and reproducibility of research outputs.
          </p>
          <p className="text-sm text-foreground mb-2">
            Through a series of hands-on workshops and expert-led sessions,
            LIBSENSE-Connect delves into key open science topics to offer
            practical insights into leveraging metadata, technical standards,
            and repository platforms to enhance information exchange, discovery,
            and data sharing. The program emphasises adherence to the FAIR
            principles (Findable, Accessible, Interoperable, and Reusable) to
            create a more connected and efficient research landscape.
          </p>
          <p className="text-sm text-foreground mb-4">
            The first workshop in Lagos in 2023 focused on understanding the
            infrastructural requirements needed to meet the needs of the open
            science community in Africa. The Abuja Workshop in 2024 centered on
            intitutional requirement for integration into the shared national
            repository developed by WACREN under the AfricaConnect3 project
            (co-funded by the European Union)
          </p>
          <div className="flex space-x-4">
            <Link
              to=""
              className="hover:bg-primary-600 flex-none bg-primary px-4 py-2 text-primary-foreground"
            >
              Read more
            </Link>
            <Link
              to="https://indico.wacren.net/category/41/"
              target="_blank"
              className="hover:bg-primary-600 flex-none bg-primary px-4 py-2 text-primary-foreground"
            >
              Events
            </Link>
          </div>
        </section>
        {/* Section 4: LIBSENSE Network of Experts */}
        <section className="mb-8">
          <h3 className="text-lg font-semibold text-foreground mb-4">
            LIBSENSE Network of Experts
          </h3>
          <p className="text-sm text-foreground mb-4">
            LIBSENSE has established a diverse Network of Experts to support
            open science initiatives across Africa. These experts, with
            knowledge and experience in various disciplines of open science and
            open access, provide guidance on policy development, open access
            repositories, research data management, and more. The network is
            designed to build capacity, offer mentorship, and provide tailored
            support to academic institutions, research organisations, and
            national bodies seeking to implement open science practices.
            Organisations needing expert counsel and resources can connect with
            LIBSENSE for specialised consultation.
          </p>
          <Link
            to="/network-of-experts"
            className="hover:bg-primary-600 flex-none bg-primary px-4 py-2 text-primary-foreground"
          >
            Read more
          </Link>
        </section>
        {/* Section 5: Research Development & Innovation (RDI) */}
        <section className="mb-8">
          <h3 className="text-lg font-semibold text-foreground mb-4">
            Research Development & Innovation (RDI)
          </h3>
          <p className="text-sm text-foreground mb-4">
            LIBSENSE has been involved in advancing research, development and
            innovation (RDI) in the WACREN region. LIBSENSE considers this
            essential to fostering a robust, sustainable, and impactful research
            ecosystem. By integrating RDI efforts into its strategy, LIBSENSE
            supports the growth of scientific collaboration, technological
            advancement, and innovative solutions across the region. LIBSENSE
            collaborated with other partners to organise the National RDI
            Coordination Summit in Abuja, Nigeria and coordinated a session on
            the role of open data in transforming RDI practices. LIBSENSE is
            open to collaborating in similar efforts in other countries in the
            WACREN region and beyond.
          </p>
          <Link
            to="https://www.rdi-coordination,ng"
            className="hover:bg-primary-600 flex-none bg-primary px-4 py-2 text-primary-foreground"
          >
            Read more
          </Link>
        </section>
        <ButtonGroup />
      </div>
    </>
  );
};

export default ProjectsInitiative;
