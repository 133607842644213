import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";

type Theme = {
  mode: string;
  color: string;
  textSize: string;
  underlineLinks: boolean;
  readableFont: boolean;
};

interface ThemeContextProps {
  theme: Theme;
  setTheme: React.Dispatch<React.SetStateAction<Theme>>;
  toggleThemeMode: (mode: string) => void;
  setThemeColor: (color: string) => void;
  increaseTextSize: () => void;
  decreaseTextSize: () => void;
  toggleUnderlineLinks: () => void;
  toggleReadableFont: () => void;
  resetTheme: () => void;
}

const textSizes = [
  "text-xxs",
  "text-xs",
  "text-sm",
  "text-base",
  "text-lg",
  "text-xl",
  "text-xxl",
  // "text-xxxl",
];

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [theme, setTheme] = useState<Theme>({
    mode: "light",
    color: "blue",
    textSize: "text-lg",
    underlineLinks: false,
    readableFont: false,
  });

  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      setTheme(JSON.parse(storedTheme));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("theme", JSON.stringify(theme));
    document.documentElement.className = `${theme.mode} ${theme.textSize}`;
    if (theme.underlineLinks) {
      document.documentElement.classList.add("underline-links");
    } else {
      document.documentElement.classList.remove("underline-links");
    }
    if (theme.readableFont) {
      document.documentElement.classList.add("readable-font");
    } else {
      document.documentElement.classList.remove("readable-font");
    }
    document.documentElement.setAttribute("data-theme", theme.color);
  }, [theme]);

  const toggleThemeMode = (mode: string) => {
    setTheme((prevTheme) => ({ ...prevTheme, mode }));
  };

  const setThemeColor = (color: string) => {
    setTheme((prevTheme) => ({ ...prevTheme, color }));
  };

  const increaseTextSize = () => {
    setTheme((prevTheme) => {
      const currentIndex = textSizes.indexOf(prevTheme.textSize);
      const nextIndex = Math.min(currentIndex + 1, textSizes.length - 1);
      return { ...prevTheme, textSize: textSizes[nextIndex] };
    });
  };

  const decreaseTextSize = () => {
    setTheme((prevTheme) => {
      const currentIndex = textSizes.indexOf(prevTheme.textSize);
      const nextIndex = Math.max(currentIndex - 1, 0);
      return { ...prevTheme, textSize: textSizes[nextIndex] };
    });
  };

  const toggleUnderlineLinks = () => {
    setTheme((prevTheme) => ({
      ...prevTheme,
      underlineLinks: !prevTheme.underlineLinks,
    }));
  };

  const toggleReadableFont = () => {
    setTheme((prevTheme) => ({
      ...prevTheme,
      readableFont: !prevTheme.readableFont,
    }));
  };

  const resetTheme = () => {
    setTheme({
      mode: "light",
      color: "blue",
      textSize: "text-base",
      underlineLinks: false,
      readableFont: false,
    });
  };

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
        toggleThemeMode,
        setThemeColor,
        increaseTextSize,
        decreaseTextSize,
        toggleUnderlineLinks,
        toggleReadableFont,
        resetTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = (): ThemeContextProps => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};
