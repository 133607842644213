import React from "react";
// import HeaderImage from "../../../components/HeaderImage";

const Institutional: React.FC = () => {
  const handleDocumentClick = () => {
    window.open(
      "/assets/pdf/Institutonal-Open-Science-Policy-Template.pdf",
      "_blank"
    );
  };
  return (
    <>
      <p className="text-sm text-foreground mb-4">
        At the institutional level, LIBSENSE is organising a series of regional
        workshops collocated with the REN conferences/meetings of UA, WACREN and
        ASREN that target decision-makers responsible for developing and
        implementing open science policies around research and education. Among
        the stakeholder groups are deputy-vice chancellors responsible for
        academic affairs or research and development in African higher education
        institutions; directors of postgraduate study responsible for researcher
        development and quality assurance; and library directors accountable for
        supporting the infrastructures for implementing open science.
      </p>
      <p className="text-sm text-foreground mb-4">
        The focus of these workshops is how to move from the high-level goals,
        principles and policies of open science as articulated, for example, in
        the UNESCO recommendations on open science, to the role of institutions
        in implementing these policies. For this activity, LIBSENSE has been
        partnering with associations in which senior HEI executives are already
        active such as AAU, AARU, CRUFAOCI, IUCEA, and SARUA, to co-organise the
        workshops. Since these senior executives are working at the
        institutional level, their work would complement activity at the
        national level and help progress the open science agenda across the
        African region.
      </p>

      {/* Buttons */}
      <div className="flex justify-center space-x-4 mt-8">
        <button
          className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
          onClick={handleDocumentClick}
        >
          Document
        </button>
      </div>
    </>
  );
};

export default Institutional;
