import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const logos = [
  {
    url: "/assets/images/AC3 logo.png",
    website: "https://africaconnect3.net",
  },
  {
    url: "/assets/images/EU logo.png",
    website:
      "https://international-partnerships.ec.europa.eu/policies/programming/projects/africaconnect_en",
  },
  {
    url: "/assets/images/libsense.png",
    website: "https://libsense.ren.africa/en/",
  },
  {
    url: "/assets/images/Wacren-Logo.jpg",
    website: "https://wacren.net/en/",
  },

  {
    url: "/assets/images/UNESCO.jpg",
    website: "https://wacren.net/en/",
  },
  {
    url: "/assets/images/ua-logo.png",
    website: "https://wacren.net/en/",
  },
  {
    url: "/assets/images/TUOS_PRIMARY_LOGO_FULL-COLOUR.jpg",
    website: "https://wacren.net/en/",
  },
  {
    url: "/assets/images/sarua.jpg",
    website: "https://wacren.net/en/",
  },
  {
    url: "/assets/images/RUFORUM-Logo.jpg",
    website: "https://wacren.net/en/",
  },

  {
    url: "/assets/images/ORCID-logo.jpg",
    website: "https://wacren.net/en/",
  },
  {
    url: "/assets/images/openaire_logo.jpg",
    website: "https://wacren.net/en/",
  },

  {
    url: "/assets/images/NII-1.jpg",
    website: "https://wacren.net/en/",
  },

  {
    url: "/assets/images/MarcaAplicacionesLAReferencia.jpg",
    website: "https://wacren.net/en/",
  },

  {
    url: "/assets/images/logo.png",
    website: "https://wacren.net/en/",
  },

  {
    url: "/assets/images/logo_0.png",
    website: "https://wacren.net/en/",
  },

  {
    url: "/assets/images/IUCEA.jpeg",
    website: "https://wacren.net/en/",
  },

  {
    url: "/assets/images/EIFL_logo.jpg",
    website: "https://wacren.net/en/",
  },

  {
    url: "/assets/images/DSTN-logo.png",
    website: "https://wacren.net/en/",
  },

  {
    url: "/assets/images/DORA.png",
    website: "https://wacren.net/en/",
  },

  {
    url: "/assets/images/CVC.png",
    website: "https://wacren.net/en/",
  },

  {
    url: "/assets/images/CRUFAOCI.jpg",
    website: "https://wacren.net/en/",
  },

  {
    url: "/assets/images/coko-foundation-logo.png",
    website: "https://wacren.net/en/",
  },

  {
    url: "/assets/images/coar-logo.jpg",
    website: "https://wacren.net/en/",
  },

  {
    url: "/assets/images/CAMES-1.jpeg",
    website: "https://wacren.net/en/",
  },
  {
    url: "/assets/images/AWUA.png",
    website: "https://wacren.net/en/",
  },
];

const LogoCarousel: React.FC = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          autoplay: true,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
    ],
  };

  const handleClick = (web: string) => {
    window.open(web, "_blank");
  };

  return (
    <div className="container mx-auto my-40 lg:container">
      <h2 className="text-left text-xxl font-semibold text-foreground uppercase mb-6">
        FUNDERS & PARTNERS
      </h2>
      <Slider {...settings}>
        {logos.map((logo, index) => (
          <div
            key={index}
            className="flex cursor-pointer justify-center items-center p-4"
            onClick={() => handleClick(logo.website)}
          >
            <img
              src={logo.url}
              alt={`Logo ${index + 1}`}
              className="w-full h-24 object-contain bg-background"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default LogoCarousel;
