import React from "react";
import WhatWeDo from "../../components/ReusableData";
import HeaderImage from "../../components/HeaderImage";
import Institutional from "./OPS/Institution";
import NationalPage from "./OPS/National";
import RegionalPage from "./OPS/Regional";
import ContinentalPage from "./OPS/Continental";

const OpenPolicyPage: React.FC = () => {
  const sections = ["Institutional", "National", "Regional", "Continental"];
  const content = {
    Institutional: <Institutional />,
    National: <NationalPage />,
    Regional: <RegionalPage />,
    Continental: <ContinentalPage />,
  };

  return (
    <>
      <HeaderImage />
      <WhatWeDo
        sections={sections}
        content={content}
        title="Open Science Policy & Governance"
        paragraphs={[
          "LIBSENSE is advancing open science across Africa through a multi-faceted approach. Its efforts focus on improving access to scientific knowledge and fostering a culture of collaboration and transparency in research. Key initiatives include building institutional open science capacity, national open science policies, governance and shared infrastructure, regional leadership policy engagement and continental open science infrastructure.",
        ]}
      />
    </>
  );
};

export default OpenPolicyPage;
