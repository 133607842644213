import React from "react";
import CollaborationCard from "../../components/CollaborationsCard";
import { NavLink } from "react-router-dom";
import HeaderImage from "../../components/HeaderImage";

const ImpactSection: React.FC = () => {
  return (
    <>
      <HeaderImage />

      <div className="max-w-4xl mx-auto mb-12 p-4">
        <h2 className="text-center text-xxl font-bold text-foreground mb-8">
          Impact
        </h2>

        <div className="space-y-8">
          <CollaborationCard
            title="Open Science Policy"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
          />

          <CollaborationCard
            title="Research Data Management Practices"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          />

          <CollaborationCard
            title="Capacity Building"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          />
        </div>

        {/* Video Testimonies */}
        <div className="grid grid-cols-1 md:grid-cols-3 mt-8 space-x-4">
          {/* Video 1 */}
          <div className="flex flex-col justify-center items-center gap-2">
            <video controls className="w-full h-48 bg-black">
              <source src="video1.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <NavLink
              to=""
              className={({ isActive }) =>
                `block text-center border border-foreground mb-4 p-2 rounded-md text-foreground transition duration-300 ${isActive ? "text-foreground" : "hover:text-primary"}`
              }
            >
              Video Testimony
            </NavLink>
          </div>

          {/* Video 2 */}
          <div className="flex flex-col justify-center items-center gap-2">
            <video controls className="w-full h-48 bg-black">
              <source src="video2.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <NavLink
              to=""
              className={({ isActive }) =>
                `block text-center border border-foreground mb-4 p-2 rounded-md text-foreground transition duration-300 ${isActive ? "text-foreground" : "hover:text-primary"}`
              }
            >
              Video Testimony
            </NavLink>
          </div>

          {/* Video 3 */}
          <div className="flex flex-col justify-center items-center gap-2">
            <video controls className="w-full h-48 bg-black">
              <source src="video3.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <NavLink
              to=""
              className={({ isActive }) =>
                `block text-center border border-foreground mb-4 p-2 rounded-md text-foreground transition duration-300 ${isActive ? "text-foreground" : "hover:text-primary"}`
              }
            >
              Video Testimony
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImpactSection;
