import React from "react";
import { Link } from "react-router-dom";

const Workshop4: React.FC = () => {
  return (
    <>
      <p className="text-sm text-foreground mb-4">
        LIBSENSE and DORA [Declaration of Research Assessment] held a one-day
        webinar on building research assessment into open science regional
        policy. The webinar drew participants from the research and education
        space and activists of open science in West Africa and beyond. It aimed
        to sensitise participants to the concept and rationale of responsible
        research assessment from a unique African perspective.
      </p>
      <p className="text-sm text-foreground mb-4">
        In his presentation, Omo Oaiya, the Chief Strategy Officer for WACREN,
        showed how the LIBSENSE programme is advancing in creating and fostering
        a community of practice for open science in Africa, taking into
        consideration the unique contexts and nuances that characterised the
        phenomenon in the continent. He highlighted LIBSENSE’s projects and
        activities in policy advocacy, infrastructure framework development and
        capacity building for the minds and brains behind implementing the
        UNESCO Recommendations on Open Science.
      </p>
      <p className="text-sm text-foreground mb-4">
        Dr Laura Rovelli, the Coordinator of FOLEC-CLACSO (a Latin America Forum
        for Research Assessment), highlighted how FOLEC was formulated and
        contributes to quality research assessment in that part of the world.
      </p>
      <p className="text-sm text-foreground mb-4">
        Presenting on the topic ‘Research Assessment in Africa: Issues to
        Consider’, Prof. Frederick Ato Armah opined that there is a need for
        strong, fluid collaboration and engagement among various research
        stakeholders to ensure that they are all in sync with the acceptable
        standardised way of assessment research that aligns with the local
        nuances.
      </p>

      <p className="text-sm text-foreground mb-14">
        Watch the video.{" "}
        <Link
          to="https://www.youtube.com/watch?v=bfTA7bx_1KY"
          target="_blank"
          className="text-primary"
        >
          here.
        </Link>
      </p>
    </>
  );
};

export default Workshop4;
