import React from "react";
import { Link } from "react-router-dom";

const Workshop2: React.FC = () => {
  return (
    <>
      <p className="text-sm text-foreground mb-4">
        The 2nd edition of the Regional Policy Workshop, themed 'Open Science
        and Research Assessment Reform, ' was held in Abidjan in the WACREN 2022
        Conference week. The event was attended by representatives of different
        partners, including the European Commission officials, CAMES, CRUFAOCI,
        Committee of Vice-Chancellors of Nigerian Universities (CVCNU),
        Association of West African Universities (AWAU), and national RENs. Also
        in attendance were Vice-Chancellors, deputy vice-chancellors, rectors,
        deans responsible for research and innovation, and senior librarians
        representing mostly Francophone and Anglophone countries in the WACREN
        region.
      </p>
      <p className="text-sm text-foreground mb-4">
        Ana Persic, the Acting Chief of the Section for Science Policy and
        Partnerships at the Division of Science Policy and Capacity Building at
        UNESCO, presented, while Prof. Stephen Curry, the Chair of the
        Declaration of Research Assessment (DORA), gave the keynote address. The
        30 in-person participants had a break-out session where they discussed
        the issues and challenges in implementing open science policies in the
        region.
      </p>
      <p className="text-sm text-foreground mb-4">
        The day ended with an{" "}
        <Link
          to="/assets/pdf/LIBSENSE-Regional-Open-Science-Policy-Development-Workshop-Case-Studies_v2.pdf"
          target="_blank"
          className="text-primary"
        >
          updated compendium
        </Link>{" "}
        of case studies highlighting various initiatives and activities by
        institutions and countries to advance open science through policy
        formulation and implementation.
      </p>
      <p className="text-sm text-foreground mb-4">
        See photos of the Workshop{" "}
        <Link
          to="https://photos.wacren.net/index.php?/category/36"
          target="_blank"
          className="text-primary"
        >
          here.
        </Link>
      </p>

      <p className="text-sm text-foreground mb-4">
        Watch the full video.{" "}
        <Link
          to="https://youtu.be/HZ6kGGLGU1o"
          target="_blank"
          className="text-primary"
        >
          here.
        </Link>
      </p>

      <div className="flex items-center justify-center mb-14">
        <img
          className=" bg-cover"
          src="/assets/images/workshop/workshop2.jpg"
          alt="workshop"
        />
      </div>
    </>
  );
};

export default Workshop2;
