import React from "react";
import { NavLink } from "react-router-dom";

interface CollaborationCardProps {
  title: string;
  description: string;
  buttons?: string[];
  importantLinks?: string[];
}

const CollaborationCard: React.FC<CollaborationCardProps> = ({
  title,
  description,
  buttons,
  importantLinks,
}) => {
  return (
    <div className="border rounded-lg p-6 bg-gray-100 flex flex-col gap-4">
      <div className="flex items-start gap-4">
        <img
          src="/assets/images/check-mark.png"
          alt="check-mark"
          className="h-8 w-8"
        />
        <div>
          <h3 className="text-lg font-semibold text-foreground mb-4">
            {title}
          </h3>
          <p className="text-sm text-foreground mb-4">{description}</p>
        </div>
      </div>

      {buttons && (
        <div className="flex items-center justify-center gap-6 mt-4">
          {buttons.map((button, index) => (
            <NavLink
              to=""
              key={index + 1}
              className={({ isActive }) =>
                `block text-center border border-foreground mb-4 px-6 py-4 rounded-md text-foreground transition duration-300 ${isActive ? "text-foreground" : "hover:text-primary"}`
              }
            >
              {button}
            </NavLink>
          ))}
        </div>
      )}

      {importantLinks && (
        <div className="mt-4">
          <h4 className="font-semibold text-foreground mb-2">
            Important Links
          </h4>
          <ul className="list-disc list-inside space-y-2">
            {importantLinks.map((link, index) => (
              <li key={index} className="text-sm text-foreground">
                {link}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CollaborationCard;
