import React from "react";
import { Link } from "react-router-dom";

const ContinentalPage: React.FC = () => {
  return (
    <>
      <section>
        <div className="grid grid-cols-2 gap-6 text-left text-sm text-foreground">
          <div>
            <h2 className="text-lg font-bold text-foreground mb-4 relative before:content-['—'] before:text-primary before:mr-2">
              <Link
                to="https://zenodo.org/record/4017999#.ZCv8kRXP0VG"
                className="text-primary"
              >
                UNESCO Open Science Recommendations
              </Link>
            </h2>
            <p>
              At the continental level, LIBSENSE has forged strategic
              partnerships with UNESCO. LIBSENSE participated in the global
              consultation process towards the standard-setting instrument to
              guide the global path for open science. Thoughts were included in
              the draft UNESCO Recommendations on Open Science; this draft was
              ratified at the General Conference of UNESCO on November 23, 2021.
              The Recommendation provides a critically valuable framework for
              the adoption of open science policies and practices in countries
              around the world.
            </p>
            <Link
              target="_blank"
              to="https://zenodo.org/record/4017999#.ZCv8kRXP0VG"
              className="text-primary"
            >
              Read the statement prepared by LIBSENSE for UNESCO, where the
              former proposed a set of principles and actions for open science
              indigenous to Africa.
            </Link>

            <div className="w-full lg:w-1/2 flex-shrink-0 mt-8 mb-14">
              <img
                className="w-full h-full bg-cover"
                src="/assets/images/UNESCO.jpg"
                alt="UNESCO.jpg"
              />
            </div>
          </div>
          <div>
            <h2 className="text-lg font-bold text-foreground mb-4 relative before:content-['—'] before:text-primary before:mr-2">
              <Link to="/ruforum" className="text-primary">
                Research Data Management in Agriculture
              </Link>
            </h2>
            <p>
              LIBSENSE and RUFORUM are in agreement to provide contemporary
              platforms to enable agriculture research data sharing and open
              access publishing. As part of the collaboration, training on
              research data management will be developed as repeatable open
              education resources (OER) for RUFORUM researchers and librarians.
            </p>

            <div className="w-full flex-shrink-0 mt-8 mb-14">
              <img
                className="w-full h-full bg-cover"
                src="/assets/images/RUFORUM-Logo.jpg"
                alt="RUFORUM-Logo.jpg"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContinentalPage;
